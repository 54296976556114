import { API_URL, REACT_APP_VERSION } from '../../utils/constants';
import {
  FETCHING_PROJECT,
  FETCHING_PROJECT_SUCCESS,
  FETCHING_PROJECT_FAILED,
  FETCHING_PROJECT_SURVEYS,
  FETCHING_PROJECT_SURVEYS_SUCCESS,
  FETCHING_PROJECT_SURVEYS_FAILED,
  TOGGLE_CREATE_SURVEY_MODAL,
  TOGGLE_ALTERNATE_SIDEBAR,
  TOGGLE_SIDEBAR,
  CREATING_SURVEY,
  CREATING_SURVEY_SUCCESS,
  CREATING_SURVEY_FAILED,
  CLONING_SURVEY,
  CLONING_SURVEY_SUCCESS,
  CLONING_SURVEY_FAILED,
  DELETING_SURVEY,
  DELETING_SURVEY_SUCCESS,
  DELETING_SURVEY_FAILED,
  SET_ACTIVE_SURVEY,
  UNSET_ACTIVE_SURVEY,
  FETCHING_SURVEY,
  FETCHING_SURVEY_SAVING,
  FETCHING_SURVEY_SUCCESS,
  FETCHING_SURVEY_FAILED,
  FETCHING_SURVEY_RESPONSES,
  FETCHING_SURVEY_RESPONSES_SUCCESS,
  FETCHING_SURVEY_RESPONSES_FAILED,
  DELETING_SURVEY_RESPONSES,
  DELETING_SURVEY_RESPONSES_SUCCESS,
  DELETING_SURVEY_RESPONSES_FAILED,
  DOWNLOADING_RESPONSES_IN_EXCEL,
  DOWNLOADING_RESPONSES_IN_EXCEL_FAILED,
  DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS,
  FETCHING_FORM_REPORT,
  FETCHING_FORM_REPORT_SUCCESS,
  FETCHING_FORM_REPORT_FAILED,
  FETCHING_COLLAB_PROJECTS,
  FETCHING_COLLAB_PROJECTS_FAILED,
  FETCHING_COLLAB_PROJECTS_SUCCESS,
  UPLOADING_TREE_EXCEL_SHEET,
  UPLOADING_TREE_EXCEL_SHEET_SUCCESS,
  UPLOADING_TREE_EXCEL_SHEET_FAILED,
  FETCHING_FILTERED_SURVEY_RESPONSES,
  FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS,
  FETCHING_FILTERED_SURVEY_RESPONSES_FAILED,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED,
  TOGGLE_CREATE_REVIEW_EXERCISE_MODAL,
  CREATE_REVIEW_EXERCISE,
  CREATE_REVIEW_EXERCISE_SUCCESS,
  CREATE_REVIEW_EXERCISE_FAILED,
  FETCH_REVIEW_EXERCISE,
  FETCH_REVIEW_EXERCISE_SUCCESS,
  FETCH_REVIEW_EXERCISE_FAILED,
  TOGGLE_DOWNLOAD_LATER_MODAL,
  UPDATE_REVIEW_EXERCISE,
  UPDATE_REVIEW_EXERCISE_SUCCESS,
  UPDATE_REVIEW_EXERCISE_FAILED,
  FETCH_INVITATIONS,
  FETCH_INVITATIONS_SUCCESS,
  FETCH_INVITATIONS_FAILED,
  TOGGLE_NOTIFICATIONS_MODAL,
  DECLINE_INVITE,
  DECLINE_INVITE_SUCCESS,
  DECLINE_INVITE_FAILED,
  APPROVE_INVITE_FAILED,
  APPROVE_INVITE_SUCCESS,
  APPROVE_INVITE,
  // SEARCH_REVIEW_LIST,
  // SEARCH_REVIEW_LIST_SUCCESS,
  // SEARCH_REVIEW_LIST_FAILED,
  ASSIGN_LIST_TO_AGENT,
  ASSIGN_LIST_TO_AGENT_SUCCESS,
  ASSIGN_LIST_TO_AGENT_FAILED,
  FETCH_PROJECT_AGENTS,
  FETCH_PROJECT_AGENTS_SUCCESS,
  FETCH_PROJECT_AGENTS_FAILED,
  SAVING_BILLING_INTENT,
  SAVING_BILLING_INTENT_FAILED,
  SAVING_BILLING_INTENT_SUCCESS,
  POSTING_BILLING_INFO,
  POSTING_BILLING_INFO_SUCCESS,
  POSTING_BILLING_INFO_FAILED,
} from '../../utils/types';

import axios from 'axios';
import { logoutUser } from '../../actions/auth';
import { sendMetric } from '../../actions/prom';
import { notification } from 'antd';

import { replace } from 'react-router-redux';
import messages from '../../utils/commonwords';
import { getCookie, setCookie } from '../../utils/helpers';
// import { fetchReviewExercises } from '../ReviewExercises/actions';

export const resetSurveyResponses = () => {
  return dispatch => {
    dispatch({ type: 'RESET_SURVEY_RESPONSES' });
  };
};

export const updateSurveysList = (survey, actionType) => {
  if (survey) {
    return dispatch => {
      dispatch({ type: 'UPDATE_SURVEY_LIST', survey, actionType });
    };
  }
};

export const setActiveSurvey = (id, status) => {
  return dispatch => {
    dispatch({ type: SET_ACTIVE_SURVEY, id, status });
  };
};

export const unsetActiveSurvey = () => {
  return dispatch => {
    dispatch({ type: UNSET_ACTIVE_SURVEY });
  };
};

export const toggleCreateSurveyModal = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_CREATE_SURVEY_MODAL });
  };
};
export const toggleCreateReviewExerciseModal = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_CREATE_REVIEW_EXERCISE_MODAL });
  };
};

export const toggleSidebarOnMobile = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };
};

export const toggleAlternateSidebar = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_ALTERNATE_SIDEBAR });
  };
};

export const hideVerifyAccModal = () => {
  return dispatch => {
    dispatch({ type: 'HIDE_VERIFY_MODAL' });
  };
};

export const setFormSettings = (formId, settings, intl, type, onOK = null) => {
  return dispatch => {
    dispatch({ type: 'FORM_SETTING' });

    return axios({
      method: 'put',
      url: `${API_URL}/surveys/preferences/${formId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: settings,
    })
      .then(response => {
        if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
          onOK && onOK();
          dispatch({ type: 'FORM_SETTING_SUCCESS', settings });
          if (type) {
            notification.info({
              message: intl.formatMessage(
                messages.formPreferencesUpdateSuccess
              ),
            });
          } else {
            if (settings.preferences.auto_publish === true) {
              notification.info({
                message: intl.formatMessage(messages.autoPublishOn),
              });
            } else {
              notification.info({
                message: intl.formatMessage(messages.autoPublishOff),
              });
            }
          }
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: 'FORM_SETTING_FAILED' });
          notification.error({
            message: intl.formatMessage(messages.formPreferencesUpdateFailed),
          });
        }
      })
      .catch(error => {
        dispatch({ type: 'FORM_SETTING_FAILED' });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const createSurvey = (surveyData, projectId) => {
  return dispatch => {
    dispatch({ type: CREATING_SURVEY });

    return axios({
      method: 'post',
      url: `${API_URL}/surveys?project_id=${projectId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: surveyData,
    })
      .then(response => {
        if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
          setCookie('survey_id', response.data.result.id);
          dispatch({
            type: CREATING_SURVEY_SUCCESS,
            activeSurvey: response.data.result,
          });

          return response.data;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: CREATING_SURVEY_FAILED,
            create_survey_error_msg: response.data.msg,
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CREATING_SURVEY_FAILED,
          create_survey_error_msg: error,
        });
      });
  };
};

export const cloneTemplate = (intl, templateId) => {
  return dispatch => {
    dispatch({ type: CLONING_SURVEY });

    return axios({
      method: 'get',
      url: `${API_URL}/templates/${templateId}?useTemplate=true`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
          dispatch({
            type: CLONING_SURVEY_SUCCESS,
            form: response.data.data,
          });

          notification.success({
            message: intl.formatMessage(messages.cloningSuccess),
          });

          setCookie('survey_id', response.data.data.id);

          dispatch(
            replace(
              `/${getCookie('site_lang') || 'en'}/forms/${
                response.data.data.id
              }`
            )
          );
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: CLONING_SURVEY_FAILED,
          });
          notification.error({
            message: intl.formatMessage(messages.cloningFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CLONING_SURVEY_FAILED,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const cloneSurvey = (intl, surveyId) => {
  return dispatch => {
    dispatch({ type: CLONING_SURVEY });

    return axios({
      method: 'post',
      url: `${API_URL}/surveys/${surveyId}/clone`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
          dispatch({
            type: CLONING_SURVEY_SUCCESS,
            form: response.data.data,
          });

          notification.success({
            message: intl.formatMessage(messages.cloningSuccess),
          });

          setCookie('survey_id', response.data.data.id);

          dispatch(
            replace(
              `/${getCookie('site_lang') || 'en'}/forms/${
                response.data.data.id
              }/settings`
            )
          );
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: CLONING_SURVEY_FAILED,
          });
          notification.error({
            message: intl.formatMessage(messages.cloningFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CLONING_SURVEY_FAILED,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const verifyAccount = (intl, accVToken) => {
  return dispatch => {
    dispatch({ type: 'VERIFYING_ACCOUNT' });
    return axios({
      method: 'post',
      url: `${API_URL}/auth/accountverify/${accVToken}`,
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: 'VERIFYING_ACCOUNT_SUCCESS',
          });
          dispatch(replace(`/${getCookie('site_lang') || 'en'}/forms`));
          notification.success({
            message: intl.formatMessage(messages.verificationAccSuccess),
          });
        } else {
          dispatch({
            type: 'VERIFYING_ACCOUNT_FAILED',
          });
          dispatch(replace(`/${getCookie('site_lang') || 'en'}/forms`));
          notification.error({
            message: intl.formatMessage(messages.verificationAccFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: 'VERIFYING_ACCOUNT_FAILED',
        });
        dispatch(replace(`/${getCookie('site_lang') || 'en'}/forms`));
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const resendVerifyEmail = (intl, userId, language) => {
  return dispatch => {
    dispatch({ type: 'RESENDING_VEMAIL' });

    return axios({
      method: 'post',
      url: `${API_URL}/users/accountverify`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        language,
        userId,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          notification.success({
            message: intl.formatMessage(messages.resendEmailSuccess),
          });
        } else {
          notification.error({
            message: intl.formatMessage(messages.resendEmailFailed),
          });
        }
      })
      .catch(error => {
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const deleteSurveyById = (id, intl, dash = '') => {
  const survey_id = id;
  return (dispatch, props) => {
    dispatch({ type: DELETING_SURVEY });
    return fetch(`${API_URL}/surveys/${id}`, {
      headers: new Headers({
        Accept: 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      }),
      method: 'delete',
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        console.log(json);
        if (json.statusCode === 200) {
          const user = JSON.parse(getCookie('user'));
          dispatch({ type: DELETING_SURVEY_SUCCESS });
          notification.info({
            message: intl.formatMessage(messages.deleteSurvey),
          });
          if (dash) {
            dispatch(fetchProject(user.project_id));
          }
          return survey_id;
        } else if (json.statusCode === 498) {
          dispatch({ type: DELETING_SURVEY_FAILED });
          logoutUser();
        } else {
          dispatch({ type: DELETING_SURVEY_FAILED });
          notification.warning({
            message: intl.formatMessage(messages.errorWithAction),
          });
        }
      })
      .catch(err => {
        dispatch({ type: DELETING_SURVEY_FAILED });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const updateSurveyInfo = (surveyInfo, surveyId, intl) => {
  return dispatch => {
    return axios({
      method: 'put',
      url: `${API_URL}/surveys/${surveyId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: surveyInfo,
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          notification.info({
            message: intl.formatMessage(messages.updateFormDetails),
          });

          dispatch({
            type: FETCHING_SURVEY_SUCCESS,
            data: response.data.result,
            total_responses: response.data.result.response_count,
          });
          return response.data.result;
        } else if (response.data.statusCode === 498) {
          notification.info({
            message: intl.formatMessage(messages.sessionExpired),
          });

          logoutUser();
        } else {
          notification.info({
            message: intl.formatMessage(messages.errorWithAction),
          });
        }
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const downloadResponsebyQuestionaireId = (
  qId,
  questionaireName,
  type,
  query,
  flag
) => {
  return dispatch => {
    dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL });
    return (
      fetch(
        `${API_URL}/surveys/${qId}/responses/download?q=${query}&type=${type}&flag=${flag}`,
        {
          headers: new Headers({
            Accept: 'application/json',
            'x-access-token': `${getCookie('user_token')}`,
          }),
        }
      )
        .then(response =>
          response.status === 206 ? response.blob() : response.json()
        )
        // return axios({
        //   method: 'get',
        //   url: `${API_URL}/surveys/${qId}/responses/download?q=${query}&type=${type}&flag=${flag}`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //     'x-access-token': `${getCookie('user_token')}`
        //   }
        // })
        .then(async response => {
          if (response.statusCode === 206) {
            dispatch({
              type: DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS,
              download_later: true,
            });
          }

          if (!response.statusCode) {
            var fileDownload = require('react-file-download');

            fileDownload(
              response,
              questionaireName + '_responses_download.xlsx'
            );
            dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS });
          }
        })
        .catch(err => {
          dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL_FAILED });
        })
    );
  };
};

export const downloadFlatResponsebyQuestionaireId = (
  qId,
  questionaireName,
  type,
  query,
  flag
) => {
  return dispatch => {
    dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL });
    return fetch(
      `${API_URL}/surveys/${qId}/responses/downloadFlat?q=${query}&type=${type}&flag=${flag}`,
      {
        headers: new Headers({
          Accept: 'application/json',
          'x-access-token': `${getCookie('user_token')}`,
        }),
      }
    )
      .then(async response => {
        if (response.data && response.data.statusCode === 200) {
          dispatch({
            type: DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS,
            download_later: true,
          });
        } else {
          var fileDownload = require('react-file-download');
          let blob = await response.blob();
          fileDownload(blob, questionaireName + '_responses_download.xlsx');
          dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS });
        }
      })
      .catch(err => {
        dispatch({ type: DOWNLOADING_RESPONSES_IN_EXCEL_FAILED });
      });
  };
};

export const updateSurvey = (
  intl,
  qData,
  questionnaireId,
  status,
  title,
  description,
  gis_loc,
  dashboard = '',
  onOk
) => {
  return dispatch => {
    let data = {
      survey_id: questionnaireId,
      json_data: qData,
      status,
      title,
      description,
      gis_loc,
      dashboard,
    };
    dispatch({ type: FETCHING_SURVEY_SAVING });
    return fetch(
      `${API_URL}/surveys/${questionnaireId}?status=${
        status ? status : 'P'
      }&init`,
      {
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': `${getCookie('user_token')}`,
        }),
        method: 'put',
        body: JSON.stringify(data),
      }
    )
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.statusCode === 200) {
          const user = JSON.parse(getCookie('user'));
          onOk && onOk();
          dispatch({
            type: FETCHING_SURVEY_SUCCESS,
            data: json.result || {},
          });
          if (dashboard) {
            dispatch(fetchProject(user.project_id));
          }
          if (data.status === 'A') {
            notification.success({
              message: intl.formatMessage(messages.formPublished),
            });
          } else if (data.status === 'R') {
            notification.success({
              message: intl.formatMessage(messages.formUnpublished),
            });
          } else {
            notification.success({
              message: intl.formatMessage(messages.formSaved),
            });
          }
        } else if (json.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: FETCHING_SURVEY_FAILED,
          });
          notification.error({
            message: intl.formatMessage(messages.errorWithAction),
          });
        }
        return json.result;
      })
      .catch(err => {
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const fetchResponsebySurveyId = (
  sId,
  page,
  limit,
  append,
  flag,
  searchValue
) => {
  limit = 70;
  return dispatch => {
    dispatch({ type: FETCHING_SURVEY_RESPONSES });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for form responses',
        {
          section: 'FormResponseRequest',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );
    return axios({
      method: 'get',
      url: `${API_URL}/surveys/${sId}/responses?page=${page || 1}&limit=${
        limit || 100
      }${flag ? `&status=${flag}` : ''}${
        searchValue ? `&searchValue=${searchValue}` : ''
      }`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: "This metric measures the duration of fetching a form's responses api call",
        labels: {
          section: 'Form Response Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`,
        },
      },
    })
      .then(response => {
        if (response.data.statusCode === 404) {
          dispatch({
            type: FETCHING_SURVEY_RESPONSES_SUCCESS,
            data: [],
            pageNumber: 0,
            total: 0,
          });
        } else if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_SURVEY_RESPONSES_SUCCESS,
            data: response.data.data,
            meta: response.data.meta,
            pageNumber: response.data.meta.pageNumber,
            total: response.data.meta.total,
            sId,
          });
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for form responses',
              {
                section: 'FormResponseRequest',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`,
              }
            )
          );
        } else {
          dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
        }
      })
      .catch(error => {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on form reesponses request',
            {
              section: 'FormResponseRequest',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`,
            }
          )
        );
        dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
      });
  };
};

export const fetchFilteredResponsebySurveyId = (
  sId,
  page,
  limit,
  append,
  status,
  searchValue
) => {
  limit = 70;
  return dispatch => {
    dispatch({ type: FETCHING_FILTERED_SURVEY_RESPONSES });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for form responses',
        {
          section: 'FormResponseRequest',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );
    return axios({
      method: 'get',
      url: `${API_URL}/surveys/${sId}/responses?page=${page || 1}&limit=${
        limit || 100
      }${status ? `&status=${status}` : ''}${
        searchValue ? `&searchValue=${searchValue}` : ''
      }`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: "This metric measures the duration of fetching a form's responses api call",
        labels: {
          section: 'Form Response Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`,
        },
      },
    })
      .then(response => {
        if (response.data.statusCode === 404) {
          dispatch({
            type: FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS,
            data: [],
            pageNumber: 0,
            total: 0,
          });
        } else if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS,
            data: response.data.data,
            meta: response.data.meta,
            pageNumber: response.data.meta.pageNumber,
            total: response.data.meta.total,
            sId,
          });
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch({ type: FETCHING_FILTERED_SURVEY_RESPONSES_FAILED });
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for form responses',
              {
                section: 'FormResponseRequest',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`,
              }
            )
          );
        } else {
          dispatch({ type: FETCHING_FILTERED_SURVEY_RESPONSES_FAILED });
        }
      })
      .catch(error => {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on form reesponses request',
            {
              section: 'FormResponseRequest',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`,
            }
          )
        );
        dispatch({ type: FETCHING_FILTERED_SURVEY_RESPONSES_FAILED });
      });
  };
};

export const fetchResponseColumnDuplicates = (
  sId,
  page,
  limit,
  append,
  duplicateColId,
  type
) => {
  limit = 70;
  return dispatch => {
    dispatch({ type: FETCHING_SURVEY_RESPONSES });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for form responses',
        {
          section: 'FormResponseRequest',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );
    return axios({
      method: 'get',
      url: `${API_URL}/surveys/${sId}/responses?page=${page || 1}&limit=${
        limit || 100
      }${
        duplicateColId
          ? type === 'withoutvalues'
            ? `&blanks_in=${duplicateColId}`
            : `&duplicates_in=${duplicateColId}`
          : ''
      }`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: "This metric measures the duration of fetching a form's responses api call",
        labels: {
          section: 'Form Response Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`,
        },
      },
    })
      .then(response => {
        if (response.data.statusCode === 404) {
          dispatch({
            type: FETCHING_SURVEY_RESPONSES_SUCCESS,
            data: [],
            pageNumber: 0,
            total: 0,
          });
        } else if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_SURVEY_RESPONSES_SUCCESS,
            data: response.data.data,
            meta: response.data.meta,
            pageNumber: response.data.meta.pageNumber,
            total: response.data.meta.total,
            sId,
          });
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for form responses',
              {
                section: 'FormResponseRequest',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`,
              }
            )
          );
        } else {
          dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
        }
      })
      .catch(error => {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on form reesponses request',
            {
              section: 'FormResponseRequest',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`,
            }
          )
        );
        dispatch({ type: FETCHING_SURVEY_RESPONSES_FAILED });
      });
  };
};

export const fetchProject = projectId => {
  return dispatch => {
    dispatch({ type: FETCHING_PROJECT });
    return axios({
      method: 'get',
      url: `${API_URL}/projects/?project_id=${
        getCookie('project_id') ? getCookie('project_id') : projectId
      }`,
      headers: {
        Accept: 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_PROJECT_SUCCESS,
            project: response.data.result,
          });
          return response.data.result;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: FETCHING_PROJECT_FAILED,
            error_msg: response.data.message,
          });
        }
      })
      .catch(error => {
        dispatch({ type: FETCHING_PROJECT_FAILED, error_msg: error });
      });
  };
};

export const fetchUserProject = projectId => {
  return dispatch => {
    dispatch({ type: 'FETCHING_USER_PROJECT' });
    return axios({
      method: 'get',
      url: `${API_URL}/projects/?project_id=${projectId}&init`,
      headers: {
        Accept: 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: 'FETCHING_USER_PROJECT_SUCCESS',
            userProject: response.data.result,
          });
          setCookie(
            'subscription_configuration',
            JSON.stringify(response.data.result.subscription_configuration)
          );
          return response.data.result;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({
            type: 'FETCHING_USER_PROJECT_FAILED',
            error_msg: response.data.message,
          });
        }
      })
      .catch(error => {
        dispatch({ type: 'FETCHING_USER_PROJECT_FAILED' });
      });
  };
};

export const fetchProjectSurveys = projectId => {
  return dispatch => {
    dispatch({
      type: FETCHING_PROJECT_SURVEYS,
    });

    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for project surveys',
        {
          section: 'ProjectSurveysRequest',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );

    return axios({
      method: 'GET',
      url: `${API_URL}/surveys?project_id=${projectId}&limit=500&excludeFields=active_data`,
      headers: {
        Accept: 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: 'This metric measures the duration of the fetch project forms api call',
        labels: {
          section: 'Project Surveys Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`,
        },
      },
    })
      .then(response => {
        if (
          response.data.statusCode === 404 ||
          response.data.statusCode === 407
        ) {
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_SUCCESS,
            surveys: [],
          });
          // dispatch(fetchReviewExercises(projectId));
        } else if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_SUCCESS,
            surveys: response.data.data,
            meta: response.data,
          });
          // dispatch(fetchReviewExercises(projectId));
          return response.data.statusCode;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for project surveys',
              {
                section: 'ProjectSurveysRequest',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`,
              }
            )
          );
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_FAILED,
          });
        } else {
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_FAILED,
          });
        }
      })
      .catch(error => {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on project surveys request',
            {
              section: 'ProjectSurveysRequest',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`,
            }
          )
        );
        dispatch({
          type: FETCHING_PROJECT_SURVEYS_FAILED,
          surveys_error_msg: error,
        });
      });
  };
};

export const fetchSurveyById = (id, status) => {
  return dispatch => {
    dispatch({
      type: FETCHING_SURVEY,
      isFetching: true,
    });

    dispatch({
      type: UNSET_ACTIVE_SURVEY,
    });

    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for forms',
        {
          section: 'FormRequest',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );

    return axios({
      method: 'get',
      url: `${API_URL}/surveys/${id}/?status=${status === 'A' ? 'R' : status}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: 'This metric measures the duration of the form request api call',
        labels: {
          section: 'Form Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`,
        },
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_SURVEY_SUCCESS,
            data: response.data.result,
          });
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch({
            type: FETCHING_SURVEY_FAILED,
          });

          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the failed number of requests made for forms',
              {
                section: 'FormRequest',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`,
              }
            )
          );
        } else {
          dispatch({
            type: FETCHING_SURVEY_FAILED,
          });
        }
      })
      .catch(err => {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on form request',
            {
              section: 'FormRequest',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`,
            }
          )
        );
        dispatch({
          type: FETCHING_SURVEY_FAILED,
          err,
        });
        // throw err;
      });
  };
};

export const deleteResponsesBySurveyId = (surveyId, ids) => {
  return dispatch => {
    dispatch({ type: DELETING_SURVEY_RESPONSES });
    return fetch(`${API_URL}/surveys/${surveyId}/responses`, {
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      method: 'delete',
      body: JSON.stringify(ids),
    })
      .then(responses => responses.json())
      .then(json => {
        if (json.statusCode === 200) {
          dispatch({
            type: DELETING_SURVEY_RESPONSES_SUCCESS,
            deletedIds: ids.resId,
          });

          return surveyId;
        } else if (json.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: DELETING_SURVEY_RESPONSES_FAILED });
        }
      })
      .catch(error => {
        dispatch({ type: DELETING_SURVEY_RESPONSES_FAILED });
      });
  };
};

export const assignResponsesToAgent = (
  intl,
  surveyId,
  agentId,
  agentName,
  ids
) => {
  return dispatch => {
    dispatch({ type: ASSIGNING_SURVEY_RESPONSES_TO_AGENT });

    return axios({
      method: 'post',
      url: `${API_URL}/surveys/${surveyId}/responses/assignment`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        responseData: {
          agentId: agentId,
          agentName: agentName,
          responseId: ids,
        },
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS,
          });
          notification.success({
            message: intl.formatMessage(
              messages.responseAssignmentToAgentSuccess
            ),
          });

          // return surveyId;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED });
          notification.error({
            message: intl.formatMessage(
              messages.responseAssignmentToAgentFailed
            ),
          });
        }
        // dispatch(fetchResponsebySurveyId(surveyId, page, limit, append, flag));
      })
      .catch(error => {
        dispatch({ type: ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });

        // dispatch(fetchResponsebySurveyId(surveyId, page, limit, append, flag));
      });
  };
};

export const fetchFormReport = id => {
  return dispatch => {
    dispatch({ type: FETCHING_FORM_REPORT });

    return axios({
      method: 'get',
      url: `${API_URL}/surveys/${id}/reports`,
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_FORM_REPORT_SUCCESS,
            report: response.data.data,
          });
        } else if (response.data.statusCode === 404) {
          dispatch({ type: FETCHING_FORM_REPORT_FAILED, report: [] });
        } else {
          dispatch({ type: FETCHING_FORM_REPORT_FAILED, report: [] });
        }
      })
      .catch(error => {
        dispatch({ type: FETCHING_FORM_REPORT_FAILED, report: [] });
      });
  };
};

export const fetchCollabProjects = () => {
  return dispatch => {
    dispatch({ type: FETCHING_COLLAB_PROJECTS });
    return axios({
      method: 'get',
      url: `${API_URL}/users/administrators/projects`,
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCHING_COLLAB_PROJECTS_SUCCESS,
            projects: response.data.data,
          });
        } else {
          dispatch({ type: FETCHING_COLLAB_PROJECTS_FAILED });
        }
      })
      .catch(error => {
        dispatch({ type: FETCHING_COLLAB_PROJECTS_FAILED });
      });
  };
};

export const fetchCollabProjectSurveys = pId => {
  return dispatch => {
    dispatch({ type: FETCHING_PROJECT_SURVEYS });
    return axios({
      method: 'get',
      url: `${API_URL}/users/administrators/projects/forms?projectId=${pId}`,
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (
          response.data.statusCode === 404 ||
          response.data.statusCode === 407
        ) {
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_SUCCESS,
            surveys: [],
          });
          // dispatch(fetchReviewExercises(pId));
        } else if (response.data.statusCode === 200) {
          // dispatch(fetchReviewExercises(pId));
          dispatch({
            type: FETCHING_PROJECT_SURVEYS_SUCCESS,
            surveys: response.data.data,
            meta: response.data,
          });
          // dispatch(fetchReviewExercises(pId));
          return response.data.statusCode;
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: FETCHING_PROJECT_SURVEYS_FAILED });
        }
      })
      .catch(error => {
        dispatch({ type: FETCHING_PROJECT_SURVEYS_FAILED });
      });
  };
};

export const uploadTreeExcel = (data, qId, pages, activePage, question) => {
  return dispatch => {
    dispatch({ type: UPLOADING_TREE_EXCEL_SHEET });

    return axios({
      method: 'post',
      url: `${API_URL}/surveys/treexlsupload`,
      headers: {
        'Content-Type': 'application/octet-stream',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data,
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: UPLOADING_TREE_EXCEL_SHEET_SUCCESS,
            data: response.data.data,
            tree_headings: response.data.columnHeaders,
            pages,
            activePage,
            qId,
            question,
          });

          setTimeout(() => {
            dispatch({
              type: UPLOADING_TREE_EXCEL_SHEET_SUCCESS,
              data: response.data.data,
              tree_headings: response.data.columnHeaders,
              pages,
              qId,
              activePage,
              question,
              hide: true,
            });
          }, 1000);
        }
      })
      .catch(error => {
        dispatch({
          type: UPLOADING_TREE_EXCEL_SHEET_FAILED,
          error,
        });
      });
  };
};

export const createReviewExercise = (intl, data, project_id, onOk) => {
  return dispatch => {
    dispatch({ type: CREATE_REVIEW_EXERCISE });

    return axios({
      method: 'POST',
      url: `${API_URL}/todos`,
      headers: {
        enctype: 'multipart/form-data',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data,
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: CREATE_REVIEW_EXERCISE_SUCCESS,
            payload: response.data,
          });
          // dispatch(fetchReviewExercises(data.survey_id));
          notification.success({
            message: intl.formatMessage(messages.createReviewExerciseSuccess),
          });
          onOk && onOk(response.data['data']);
          return response.data['data'].uuid;
        } else if (response.data.statusCode === 404) {
          dispatch({ type: CREATE_REVIEW_EXERCISE_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(messages.createReviewExerciseFailed),
          });
        } else {
          dispatch({ type: CREATE_REVIEW_EXERCISE_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(messages.createReviewExerciseFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_REVIEW_EXERCISE_FAILED,
          error,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const updateReviewExercise = (intl, reviewId, data, onOk) => {
  return dispatch => {
    dispatch({ type: UPDATE_REVIEW_EXERCISE });

    return axios({
      method: 'POST',
      url: `${API_URL}/todos/${reviewId}/todo-lists`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data,
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: UPDATE_REVIEW_EXERCISE_SUCCESS,
            payload: response.data,
          });
          onOk && onOk(response.data['data']);
          return true;
        } else if (response.data.statusCode === 404) {
          dispatch({ type: UPDATE_REVIEW_EXERCISE_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(messages.createReviewExerciseFailed),
          });
        } else {
          dispatch({ type: UPDATE_REVIEW_EXERCISE_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(messages.createReviewExerciseFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_REVIEW_EXERCISE_FAILED,
          error,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const fetchReviewExerciseList = (intl, uuid) => {
  return dispatch => {
    dispatch({ type: FETCH_REVIEW_EXERCISE });

    return axios({
      method: 'GET',
      url: `${API_URL}/todos/${uuid}/todo-lists?page=1&limit=100`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_SUCCESS,
            payload: response.data,
          });
        } else if (response.data.statusCode === 404) {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_SUCCESS,
            payload: { data: [], meta: [] },
          });
          // notification.error({ message: intl.formatMessage(messages.fetchReviewExercisesFailed) });
        } else {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_FAILED,
            payload: { data: [], meta: [] },
          });
          notification.error({
            message: intl.formatMessage(messages.fetchReviewExercisesFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_REVIEW_EXERCISE_FAILED,
          error,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const fetchInvitations = intl => {
  return dispatch => {
    dispatch({ type: FETCH_INVITATIONS });

    return axios({
      method: 'GET',
      // url: `${API_URL}/users/collaborators/invitations`,
      url: `${API_URL}/users/collaborator/invitations`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_INVITATIONS_SUCCESS,
            payload: response.data,
          });
        } else if (response.data.statusCode === 404) {
          dispatch({
            type: FETCH_INVITATIONS_SUCCESS,
            payload: { data: [] },
          });
        } else {
          dispatch({ type: FETCH_INVITATIONS_FAILED, payload: {} });
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_INVITATIONS_FAILED,
          error,
        });
      });
  };
};
export const approveInvite = (email, user_id, project_id, id) => {
  return dispatch => {
    dispatch({ type: APPROVE_INVITE });

    return axios({
      method: 'PUT',
      url: `${API_URL}/users/collaborator/decisions`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        email,
        user_id,
        project_id,
        id,
        action: 'accept',
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: APPROVE_INVITE_SUCCESS,
            payload: response.data,
          });
          return true;
        } else if (response.data.statusCode === 404) {
          dispatch({ type: APPROVE_INVITE_FAILED, payload: {} });
          return false;
        } else {
          dispatch({ type: APPROVE_INVITE_FAILED, payload: {} });
          return false;
        }
      })
      .catch(error => {
        dispatch({
          type: APPROVE_INVITE_FAILED,
          error,
        });
        return error;
      });
  };
};

export const declineInvite = (email, user_id, project_id, id) => {
  return dispatch => {
    dispatch({ type: DECLINE_INVITE });

    return axios({
      method: 'PUT',
      // url: `${API_URL}/users/collaborators/invitations`,
      url: `${API_URL}/users/collaborator/decisions`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        email,
        user_id,
        project_id,
        id,
        action: 'decline',
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: DECLINE_INVITE_SUCCESS,
            payload: response.data,
          });
          return true;
        } else if (response.data.statusCode === 404) {
          dispatch({ type: DECLINE_INVITE_FAILED, payload: {} });
          return false;
        } else {
          dispatch({ type: DECLINE_INVITE_FAILED, payload: {} });
          return false;
        }
      })
      .catch(error => {
        dispatch({
          type: DECLINE_INVITE_FAILED,
          error,
        });
        return false;
      });
  };
};
export const updateSurveyPageName = pages => {
  return dispatch => {
    dispatch({ type: 'UPDATING_PAGE_NAME', pages });
  };
};

export const setFormActivePage = (page, activeIndex) => {
  return dispatch => {
    dispatch({ type: 'SET_ACTIVE_FORM_PAGE', page, activeIndex });
  };
};

export const toggleDownloadLaterModal = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_DOWNLOAD_LATER_MODAL });
  };
};
export const toggleNotificationsModal = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_NOTIFICATIONS_MODAL });
  };
};
export const searchReviewList = (
  intl,
  uuid,
  searchQuery,
  filter,
  page,
  advSearch
) => {
  return dispatch => {
    dispatch({ type: FETCH_REVIEW_EXERCISE });

    return axios({
      method: 'get',
      // url: `${API_URL}/todos/${uuid}/todo-lists?page=1&limit=100`,
      url: `${API_URL}/todos/${uuid}/todo-lists?${
        searchQuery ? `search=${searchQuery}&` : ''
      }${filter ? `${filter.name}=${filter.value}&` : ''}page=${
        page ? page + 1 : 1
      }&limit=100${advSearch}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_SUCCESS,
            payload: response.data,
          });
        } else if (response.data.statusCode === 404) {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_SUCCESS,
            payload: { data: [], meta: [] },
          });
          // dispatch({ type: FETCH_REVIEW_EXERCISES_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(messages.fetchReviewExercisesFailed),
          });
        } else {
          dispatch({
            type: FETCH_REVIEW_EXERCISE_FAILED,
            payload: { data: [], meta: [] },
          });
          notification.error({
            message: intl.formatMessage(messages.fetchReviewExercisesFailed),
          });
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_REVIEW_EXERCISE_FAILED,
          error,
        });
        notification.error({
          message: intl.formatMessage(messages.networkError),
        });
      });
  };
};

export const assignListsToAgent = (
  intl,
  uuid,
  ids,
  access_code,
  filter,
  onOk
) => {
  return dispatch => {
    dispatch({ type: ASSIGN_LIST_TO_AGENT });
    return axios({
      method: 'PUT',
      url: `${API_URL}/todos/${uuid}/todo-lists${
        filter ? `?filter=${filter}` : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: { ids, access_code },
    })
      .then(response => {
        const res = response.data;
        if (res.statusCode === 200) {
          dispatch({ type: ASSIGN_LIST_TO_AGENT_SUCCESS, payload: res.data });
          onOk && onOk(res.data);
          // dispatch(fetchReviewExerciseList(intl, uuid));
          notification.success({
            message: intl.formatMessage(messages.listsAssignmentToAgentSuccess),
          });
        } else {
          dispatch({ type: ASSIGN_LIST_TO_AGENT_FAILED, payload: {} });
          notification.error({
            message: intl.formatMessage(
              messages.responseAssignmentToAgentFailed
            ),
          });
        }
      })
      .catch(error => {
        dispatch({ type: ASSIGN_LIST_TO_AGENT_FAILED, error });
        notification.error({
          message: intl.formatMessage(messages.responseAssignmentToAgentFailed),
        });
      });
  };
};

export const fetchProjectAgents = organizationId => {
  console.log('/agents');
  return dispatch => {
    dispatch({ type: FETCH_PROJECT_AGENTS });

    return axios({
      method: 'get',
      url: `${API_URL}/users/organizations/${organizationId}/agents`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: FETCH_PROJECT_AGENTS_SUCCESS,
            payload: response.data,
          });
        } else if (response.data.statusCode === 404) {
          dispatch({
            type: FETCH_PROJECT_AGENTS_FAILED,
          });
        } else {
          dispatch({ type: FETCH_PROJECT_AGENTS_FAILED });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_PROJECT_AGENTS_FAILED, error });
      });
  };
};

export const billingIntent = (project_uuid, user_uuid, currentUrl) => {
  return dispatch => {
    dispatch({ type: SAVING_BILLING_INTENT });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of users who have clicked the upgrade now button',
        {
          section: 'UpgradeProceedButton',
          event: 'Total',
          version: `${REACT_APP_VERSION}`,
        }
      )
    );

    return axios({
      method: 'post',
      url: `${API_URL}/billing`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        project_uuid,
        user_uuid,
        currentUrl,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: SAVING_BILLING_INTENT_SUCCESS,
            data: response.data.data,
          });
          window.location.assign(`${response.data.data.checkout_url}`);
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: SAVING_BILLING_INTENT_FAILED });
        }
      })
      .catch(error => dispatch({ type: SAVING_BILLING_INTENT_FAILED, error }));
  };
};

export const postingBillingInfo = (
  project_uuid,
  user_uuid,
  card_reference,
  pan,
  card_type,
  transaction_id
) => {
  console.log(pan);
  return dispatch => {
    dispatch({ type: POSTING_BILLING_INFO });

    return axios({
      method: 'put',
      url: `${API_URL}/billing/${project_uuid}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': `${getCookie('user_token')}`,
      },
      data: {
        user_uuid,
        card_reference,
        pan,
        card_type,
        transaction_id,
      },
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: POSTING_BILLING_INFO_SUCCESS,
            account: response.data.data,
          });
        } else if (response.data.statusCode === 498) {
          logoutUser();
        } else {
          dispatch({ type: POSTING_BILLING_INFO_FAILED });
        }
      })
      .catch(error => dispatch({ type: POSTING_BILLING_INFO_FAILED, error }));
  };
};
